import classnames from "classnames";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Card from "../../components/Card";
import backgroundImg from "../../img/background9.jpg";
import coupleImg1 from "../../img/couple/01.png";
import coupleImg2 from "../../img/couple/03.png";
import coupleImg3 from "../../img/couple/04.png";
import coupleImg4 from "../../img/couple/05.png";
import coupleImg5 from "../../img/couple/06.png";
import coupleImg6 from "../../img/couple/07.png";
import coupleCheersImg from "../../img/couple/02_cheers.png";
import { getInviteInfo, saveLastView } from "../../services/firestoreService";
import { useParams } from "react-router-dom";
import PageLoaderManager from "../../modules/pageLoader";
import useScale from "../../hooks/useScale";
import useWindowSize from "../../hooks/useWindowSize";

enum AnimState {
    init = 0,
    cardIn = 1,
    end = 2
}

const popUpCouples = [coupleImg1, coupleImg2, coupleImg3, coupleImg4, coupleImg5, coupleImg6];
let deplayedShowTimeout: NodeJS.Timeout;
let hideAndNextTimeout: NodeJS.Timeout;

const InvitationPage = () => {
    let { id } = useParams<{ id: string }>();
    const {width, height} = useWindowSize();
    const [animStage, setAnimStage] = useState(AnimState.init);
    const [loadingId, setLoadingId] = useState<string | null>(null);
    const [inviteInfo, setInviteInfo] = useState<null | Invitation>(null);
    const [loadError, setLoadError] = useState(false);
    const [popUpCoupleIndex, setPopUpCoupleIndex] = useState(0);
    const [isActivePopUpCouple, setActivePopUpCouple] = useState(false);
    const [isJustRepliedAttend, setJustRepliedAttend] = useState(false);

    const scale = useScale();

    const startPopUpCouple = (isInstant: boolean = false) => {
        const hideAndNext = () => {
            setActivePopUpCouple(false);
            deplayedShowTimeout = delayedShow();
        }

        const show = () => {
            setActivePopUpCouple(true);
            hideAndNextTimeout = setTimeout(hideAndNext, 5000);
        }

        const delayedShow = () => setTimeout(() => {
            show();
        }, 5000);

        stopPopUpCouple();
        if (isInstant) {
            show();
        } else {
            deplayedShowTimeout = delayedShow();
        }
    }

    const stopPopUpCouple = () => {
        setActivePopUpCouple(false);
        if (hideAndNextTimeout) {
            clearTimeout(hideAndNextTimeout);
        }
        if (deplayedShowTimeout) {
            clearTimeout(deplayedShowTimeout);
        }
    };

    useEffect(() => {
        if (isActivePopUpCouple) return;

        setTimeout(() => {
            let nextIndex = popUpCoupleIndex + 1;
            if (nextIndex >= popUpCouples.length) {
                nextIndex = 0;
            }
            setPopUpCoupleIndex(nextIndex);
        }, 1000);
    }, [isActivePopUpCouple]);

    useEffect(() => {
        return () => {
            stopPopUpCouple();
        }
    }, []);

    useEffect(() => {
        if (!isJustRepliedAttend) return;
        stopPopUpCouple();
        startPopUpCouple(true);
    }, [isJustRepliedAttend])

    const loadInvite = () => {
        if (loadingId && loadingId === id)
            return;

        setLoadingId(id);
        PageLoaderManager.show();
        setAnimStage(AnimState.init);
        getInviteInfo(id)
            .then(invitation => {
                setLoadingId(null);
                PageLoaderManager.hide();
                if (invitation.exists()) {
                    setLoadError(false);
                    setInviteInfo({
                        ...invitation.data(),
                        id,
                    } as Invitation);
                    setTimeout(() => setAnimStage(AnimState.cardIn), 100);
                    setTimeout(() => setAnimStage(AnimState.end), 2000);
                    startPopUpCouple();

                    saveLastView(id);
                } else {
                    setLoadError(true);
                    setInviteInfo(null);
                    setAnimStage(AnimState.end)
                }
            })
    }

    const refreshInvite = () => {
        if (loadingId && loadingId === id)
            return Promise.reject(false);

        setLoadingId(id);
        return getInviteInfo(id)
            .then(invitation => {
                setLoadingId(null);
                if (invitation.exists()) {
                    setLoadError(false);
                    setInviteInfo({
                        ...invitation.data(),
                        id,
                    } as Invitation);
                    return true;
                } else {
                    setLoadError(true);
                    setInviteInfo(null);
                    return false;
                }
            })
    }

    useEffect(() => {
        loadInvite();
    }, [id]);

    return (
        <div className={classnames([styles.invitationPage, { [styles.ready]: animStage === AnimState.end }])}>

            {/* <div className={styles.bkg__spotlight}/> */}
            { /* <div className={styles.bgCouple} style={{ backgroundImage: `url(${coupleImg1})` }} /> */}

            <div className={classnames([styles.scene, { [styles.perspective]: animStage < AnimState.end }])} style={{ backgroundImage: `url(${backgroundImg})` }}>
                {
                    loadError && (
                        <div className={classnames([styles.cardContainer, styles.in])}>
                            <div id={styles.error} style={{ transform: `scale(${scale})` }}>
                                <p>Load error. Refresh the page or try again later. Please contact us if this problem remains.</p>
                                <p>載入失敗。請重新整理頁面或者稍後再試。如問題持續，請聯絡我們。</p>
                            </div>
                        </div>
                    )
                }



                {
                    inviteInfo && !loadError && (
                        <>
                            <div className={classnames([styles.cardContainer, { [styles.in]: animStage > AnimState.init }])}>
                                <Card
                                    invitation={inviteInfo}
                                    refreshInvite={refreshInvite}
                                    onReplied={(isCome: boolean) => {
                                        if (!isCome) return;
                                        setJustRepliedAttend(true);
                                        setTimeout(() => {
                                            setJustRepliedAttend(false);
                                        }, 6000);
                                    }}
                                />
                            </div>


                        </>
                    )
                }

                
            </div>

            {
                    inviteInfo && !loadError && width && height && (
                        <div
                            className={classnames([
                                styles.popUpCouple, 
                                { 
                                    [styles.active]: isActivePopUpCouple,
                                    [styles.emphasized]: isJustRepliedAttend,
                                    [styles.vertical]: width < height,
                                    [styles.horizontal]: width >= height
                                }
                            ])}
                            style={{ backgroundImage: `url(${isJustRepliedAttend ? coupleCheersImg : popUpCouples[popUpCoupleIndex]})` }}
                        />
                    )
                }
        </div>
    );
}

export default InvitationPage;
