import { useEffect, useState } from "react";
import {
    BrowserRouter as Router, Route, Switch
} from "react-router-dom";
import styles from "./App.module.scss";
import PageLoader from "./components/PageLoader";
import SecureRoute from "./components/SecureRoute";
import AppPath from "./modules/AppPath";
import PageLoaderManager from "./modules/pageLoader";
import InvitationPage from "./pages/InvitationPage";
import InviteListPage from "./pages/InviteListPage";
import LoginPage from "./pages/LoginPage";
import AuthService from "./services/AuthService";

function App() {
    const [isShowPageLoader, setShowPageLoader] = useState(false);
    const [isInit, setInit] = useState(false);
    
    useEffect(() => {
        PageLoaderManager.init(
            () => setShowPageLoader(true),
            () => setShowPageLoader(false)
        );

        AuthService.onAuthStateChanged((user) => {
            setInit(true);
        });
        
    }, []);

    if (!isInit) {
        return <div/>;
    }

    return (
        <Router>
            <div className={styles.app}>
                <Switch>
                    <Route path={AppPath.invite}>
                        <InvitationPage />
                    </Route>
                    <Route path={AppPath.login}>
                        <LoginPage />
                    </Route>
                    <SecureRoute path={AppPath.inviteList}>
                        <InviteListPage />
                    </SecureRoute>
                </Switch>

            </div>
            <PageLoader show={isShowPageLoader} />
        </Router>
    );
}

export default App;
