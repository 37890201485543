class PageLoaderManager {
    ref?: {
        show: () => void,
        hide: () => void
    }

    init(show: () => void, hide: () => void) {
        this.ref = {show, hide};
    }

    show() {
        this.ref?.show();
    }

    hide() {
        this.ref?.hide();
    }
}

export default new PageLoaderManager;
