import React, { useState } from 'react';
import { Button, Checkbox, Form, FormInstance, Input, Modal, Select } from 'antd';

type Props = {
    formInstance: FormInstance;
    open: boolean;
    confirmLoading: boolean;
    onSubmit: (data: CreateInviteData) => void;
    onCancel: () => void;
};

const CreateInviteModal: React.FC<Props> = ({ formInstance, open, confirmLoading, onSubmit, onCancel }) => {
    const [modalText, setModalText] = useState('Content of the modal');

    const handleOk = () => {
        // setModalText('The modal will be closed after two seconds');
        // setConfirmLoading(true);
        // setTimeout(() => {
        //     setOpen(false);
        //     setConfirmLoading(false);
        // }, 2000);
    };

    const onFinish = (values: {
        displayNameEn: string;
        displayNameZh: string;
        listName: string;
        preferredLocale: Locale;
        relationshipWithCouple: string;
        side: Side;
    }) => {
        onSubmit({
            displayName: {
                en: values.displayNameEn,
                zh: values.displayNameZh
            },
            listName: values.listName,
            preferredLocale: values.preferredLocale,
            relationshipWithCouple: values.relationshipWithCouple,
            side: values.side
        });
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Modal
            title="新增邀請"
            open={open}
            confirmLoading={confirmLoading}
            footer={null}
            onCancel={onCancel}
        >
            <Form
                form={formInstance}
                name="basic"
                style={{ maxWidth: 800 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
            >
                <br />
                <h3>基本資料</h3>
                <Form.Item
                    label="全名（顯示於管理列表）"
                    name="listName"
                    rules={[{ required: true, message: '必填' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="男家／女家親友"
                    name="side"
                    rules={[{ required: true, message: '必填' }]}
                >
                    <Select>
                        <Select.Option value="F">女家</Select.Option>
                        <Select.Option value="M">男家</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="與新人的關係"
                    name="relationshipWithCouple"
                    rules={[{ required: true, message: '必填' }]}
                >
                    <Input />
                </Form.Item>

                <h3>邀請卡設定</h3>

                <Form.Item
                    label="邀請卡顯示名稱 (中文)"
                    name="displayNameZh"
                    rules={[{ required: true, message: '必填' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="邀請卡顯示名稱 (英文)"
                    name="displayNameEn"
                    rules={[{ required: true, message: '必填' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="邀請卡顯示語言偏好"
                    name="preferredLocale"
                    rules={[{ required: true, message: '必填' }]}
                >
                    <Select>
                        <Select.Option value="zh">中文</Select.Option>
                        <Select.Option value="en">英文</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={confirmLoading}
                    >
                        確定
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateInviteModal;
