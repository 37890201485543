import React, { FC } from 'react';
import cardStyles from "../card.module.scss";
import styles from "./index.module.scss";
import cardBaseImg from "../../../img/cardBase.jpeg";
import classnames from 'classnames';
import CardFlipper from '../../CardFlipper';
import i18n from '../../../modules/i18n';

type Props = BaseCardPageProps & {};

const CardFront: FC<Props> = ({ flip, invitation, selectedLocale }) => {
    const locale = i18n[selectedLocale];
    return (
        <div
            id={styles.cardFront}
            className={cardStyles.cardPage}
            style={{ backgroundImage: `url(${cardBaseImg})` }}
            onClick={flip}
        >
            <div id={styles.to}>{locale.to} {invitation.displayName[selectedLocale]}</div>
            <div id={styles.saveTheDate}>{locale.weddingParty}</div>
            <div id={styles.alexa} className={classnames([styles.name, cardStyles.sebastian])}>Alexa &</div>
            <div id={styles.jeffrey} className={classnames([styles.name, cardStyles.sebastian])}>Jeffrey</div>
            <div id={styles.address} className={styles.info}>{locale.joinMessageLn1}<br/>{locale.joinMessageLn2}</div>
            {/* <div id={styles.date} className={styles.info}>December 10, 2023<br />2:30PM - 5:00PM</div> */}

            <CardFlipper isAnimate={true} />
        </div>
    )
}

export default CardFront;
