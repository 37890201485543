import React, { FC, useState } from "react";
import 'antd/dist/reset.css';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Card } from 'antd';
import styles from "./index.module.scss";
import AuthService from "../../services/AuthService";
import { useHistory } from "react-router-dom";
import AppPath from "../../modules/AppPath";

enum FormName {
    email = "email",
    password = "password"
};

type Props = {};

const LoginPage: FC<Props> = () => {
    const history = useHistory();

    const onFinish = (values: {[key in FormName]: string}) => {
        AuthService.signInWithEmailAndPassword(values.email, values.password, (isSuccess) => {
            if (isSuccess)
                history.push(AppPath.inviteList);
            else
                alert("Login failed.");
        })
    };

    return (
        <div className={styles.loginForm}>
            <Card title="Admin Login" style={{ width: "80%", maxWidth: 500 }}>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name={FormName.email}
                        rules={[{ required: true, message: 'Please input your email' }]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="Username"
                        />
                    </Form.Item>
                    <Form.Item
                        name={FormName.password}
                        rules={[{ required: true, message: 'Please input your password' }]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
}

export default LoginPage;
