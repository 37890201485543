import React, { FC } from "react";
import styles from "./index.module.scss";

type Props = {};

const TwoSquareSpinner: FC<Props> = () => {
    return (
        <span className={styles.loader}/>
    );
}

export default TwoSquareSpinner;
