import classnames from 'classnames';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import styles from "./index.module.scss";

type Props = PropsWithChildren & {
    isExpended: boolean;
    title: string;
    width?: number;
    height?: number;
    fontSize?: number;
    selected?: boolean;
    disabled?: boolean;
    onExpend?: () => void;
    onDismiss?: () => void;
};

const ModalButton: FC<Props> = ({ isExpended, title, children, width, height, fontSize, selected, disabled, onExpend, onDismiss }) => {
    const [isShowBodyContent, setShowBodyContent] = useState(isExpended);

    useEffect(() => {
        if (isExpended)
            setTimeout(() => setShowBodyContent(true), 500);
        else
            setShowBodyContent(false);
    }, [isExpended]);

    return (
        <div
            className={classnames([
                styles.button,
                {
                    [styles.selected]: selected,
                    [styles.expended]: isExpended,
                    [styles.enabled]: !disabled
                }
            ])}
            style={{
                width: isExpended ? undefined : width,
                height: isExpended ? undefined : height,
                fontSize
            }}
            onClick={() => !disabled && onExpend && onExpend()}
        >
            <span className={styles.title}>{title}</span>
            <div className={styles.body}>
                <div
                    className={styles.closeButton}
                    onClick={(event) => {
                        event.stopPropagation();
                        onDismiss && onDismiss();
                    }}
                />
                <div style={{
                    opacity: isShowBodyContent ? 1 : 0,
                    transition: "opacity 0.5s"
                }}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default ModalButton;
