import { useEffect, useState } from "react";
import useWindowSize from "./useWindowSize";

const useScale = () => {
    const { width: vw, height: vh } = useWindowSize();
    const [scale, setScale] = useState<number>(0.7);

    useEffect(() => {
        if (vw && vh) {
            setScale(Math.min((vw - 50) / 1200, (vh - 50) / 857));
        }
    }, [vw]);
    
    return scale;
};

export default useScale;
