import classnames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';
import styles from "./index.module.scss";

type Props = PropsWithChildren & {
    width?: number;
    height?: number;
    fontSize?: number;
    selected?: boolean;
    disabled?: boolean;
    onClick?: () => void;
};

const Button: FC<Props> = ({ children, width, height, fontSize, selected, disabled, onClick }) => {
    return (
        <div
            className={classnames([styles.button, { 
                [styles.selected]: selected,
                [styles.enabled]: !disabled
            }])}
            style={{ width, height, fontSize }}
            onClick={() => !disabled && onClick && onClick()}
        >
            <span>{children}</span>
        </div>
    )
}

export default Button;
