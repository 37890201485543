import classnames from 'classnames';
import React, { FC } from 'react';
import styles from './index.module.scss';

type Props = {
    isAnimate?: boolean;
    onClick?: () => void;
};

const CardFlipper: FC<Props> = ({ isAnimate, onClick }) => {
    return (
        <div
            className={classnames([styles.triangle, {[styles.anim]: isAnimate}])}
            onClick={onClick}
        />
    )
}

export default CardFlipper;
