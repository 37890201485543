import classnames from "classnames";
import React, { FC } from "react";
import TwoSquareSpinner from "../spinners/TwoSquareSpinner";
import styles from "./index.module.scss";

type Props = {
    show: boolean;
};

const PageLoader: FC<Props> = ({ show }) => {
    return (
        <div className={classnames([styles.pageLoader, {[styles.show]: show}])}>
            <TwoSquareSpinner/>
        </div>
    )
}

export default PageLoader;
