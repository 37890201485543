import confetti from "canvas-confetti";

class ConfettiManager {
    myConfetti: confetti.CreateTypes

    constructor() {
        const canvas = document.getElementById("confetti") as HTMLCanvasElement;
        this.myConfetti = confetti.create(canvas ?? undefined, {
            resize: true,
            useWorker: true
          });
    }

    launch() {
        this.myConfetti({
            particleCount: 100,
            spread: 300,
            ticks: 400,
            scalar: 2
        });
    }
}

export default new ConfettiManager();
