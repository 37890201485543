export default {
    en: {
        clickToFlip: "Click to flip!",
        to: "To",
        weddingParty: "WEDDING PARTY",
        joinMessageLn1: "Join us for our wedding and",
        joinMessageLn2: "be part of our happily ever after",
        willAttend: "WILL ATTEND",
        willNotAttend: "WILL NOT ATTEND",
        replyDeadlineMessage: "The reply period is over. Please contact us directly if there is any change.",
        dateTimeTitle: "DATE & TIME",
        dateTimeLn1: "Sunday,",
        dateTimeLn2: "December 10",
        dateTimeLn3: "2:30PM - 4:30PM HKT",
        addressTitle: "ADDRESS",
        addressLn1: "My Palace,",
        addressLn2: "7 Kent Rd,",
        addressLn3: "Kowloon Tong",
        totalAttending: "TOTAL ATTENDING (INCLUDE YOURSELF):",
        adults: "ADULTS",
        children: "CHILDREN",
        confirm: "CONFIRM",
        youHaveReplied: "YOU HAVE REPLIED",
        noOneJoinError: "At least one person should attend to confirm the reply.",
        replyError: "Reply failed. Please try again later. Please contact us if this problem remains.",
        totalAttendCount: (adult: number, child: number) => `${adult} ADULT(S), ${child} CHILD(REN)`
    },
    zh: {
        clickToFlip: "按此反向背面!",
        to: "致",
        weddingParty: "我們的結婚派對",
        joinMessageLn1: "請參與我們的婚禮派對，",
        joinMessageLn2: "見證我們幸福快樂的到來。",
        willAttend: "將會參加",
        willNotAttend: "不會參加",
        replyDeadlineMessage: "回覆時限已過。如需更改，請直接聯繫我們。",
        dateTimeTitle: "日期與時間",
        dateTimeLn1: "星期日",
        dateTimeLn2: "12月10日",
        dateTimeLn3: "下午2:30 - 4:30 HKT",
        addressTitle: "地址",
        addressLn1: "九龍塘",
        addressLn2: "根德道7號",
        addressLn3: "四季薈",
        totalAttending: "參加總人數 (包含你自己在內):",
        adults: "成人",
        children: "小童",
        confirm: "確認",
        youHaveReplied: "你已回覆",
        noOneJoinError: "應有至少一人參加。",
        replyError: "回覆失敗，請重新整理或稍後再試。如問題持續，請通知我們。",
        totalAttendCount: (adult: number, child: number) => `${adult} 位成人, ${child} 位小童`
    }
}