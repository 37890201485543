import React, { FC, useEffect, useState } from 'react';
import cardStyles from "../card.module.scss";
import styles from "./index.module.scss";
import cardBaseImg from "../../../img/cardBase.jpeg";
import classnames from 'classnames';
import Button from '../../Button';
import CardFlipper from '../../CardFlipper';
import ModalButton from '../../ModalButton';
import classNames from 'classnames';
import QuantityPicker from '../../QuantityPicker';
import LightButton from '../../LightButton';
import { replyInvite } from '../../../services/firestoreService';
import PageLoaderManager from "../../../modules/pageLoader";
import ConfettiManager from "../../../modules/confetti";
import i18n from '../../../modules/i18n';

type Props = BaseCardPageProps & {};

const BUTTON_PROPS = {
    width: 300,
    height: 80,
    fontSize: 20
};

enum FormError {
    noAttendent,
    unknown
};

const CardBack: FC<Props> = ({ flip, invitation, refreshInvite, selectedLocale, onReplied }) => {
    const locale = i18n[selectedLocale];
    const [isExpendedAttendModal, setExpendedAttendModal] = useState(false);
    const [adultCount, setAdultCount] = useState(invitation.replyAdultCount ?? 0);
    const [childCount, setChildCount] = useState(invitation.replyChildCount ?? 0);
    const [formError, setFormError] = useState<null | FormError>(null);

    const onSubmitForm = (isCome: boolean) => {
        setFormError(null);

        if (isCome && adultCount + childCount === 0) {
            setFormError(FormError.noAttendent);
            return;
        }

        // console.log("PageLoaderManager", PageLoaderManager);
        PageLoaderManager?.show();
        replyInvite(
            invitation.id,
            isCome,
            isCome ? adultCount : null,
            isCome ? childCount : null
        )
            .then(() => {
                refreshInvite()
                    .then(() => {
                        onAttendModalDismiss();
                        PageLoaderManager?.hide();
                    }, () => {
                        alert("Refresh invitation card failed, please refresh the page.");
                        PageLoaderManager?.hide();
                    })
                    .then(() => {
                        onReplied(isCome);
                        if (isCome)
                            ConfettiManager.launch();
                    });
                
            })
            .catch((err) => {
                setFormError(FormError.unknown);
            })
    };

    const onAttendModalDismiss = () => {
        setExpendedAttendModal(false);
        setAdultCount(0);
        setChildCount(0);
        setFormError(null);
    };

    useEffect(() => {
        if (invitation.replyIsCome !== null) {
            setAdultCount(invitation.replyAdultCount ?? 0);
            setChildCount(invitation.replyChildCount ?? 0);
        } else {
            setAdultCount(0);
            setChildCount(0);
        }
    }, [invitation]);

    const isReplied = invitation.replyIsCome !== null;
    const isRepliedCome = isReplied && invitation.replyIsCome === true;
    const isRepliedNotCome = isReplied && invitation.replyIsCome === false;

    const renderFormError = (formError: null | FormError) => {
        if (formError === null) {
            return null;
        }

        let msg;
        if (formError === FormError.noAttendent) {
            msg = locale.noOneJoinError;
        } else {
            msg = locale.replyError;
        }

        return (
            <div className={styles.errMsg}>{msg}</div>
        )
    }

    return (
        <div id={styles.cardBack} className={cardStyles.cardPage} style={{ backgroundImage: `url(${cardBaseImg})` }}>
            {/* <div id={styles.background} /> */}
            <div id={styles.title}>Alexa & Jeffrey</div>

            {
                isRepliedCome && (
                    <div className={classnames([styles.youHaveReplied, styles.attend])}>
                        ↓ {locale.youHaveReplied} ↓
                    </div>
                )
            }

            {
                isRepliedNotCome && (
                    <div className={classnames([styles.youHaveReplied, styles.notAttend])}>
                        ↓ {locale.youHaveReplied} ↓
                    </div>
                )
            }

            <div
                id={styles.attendButton}
                className={classNames(
                    styles.attendenceButton,
                    {
                        [styles.expended]: isExpendedAttendModal
                    }
                )}
            >
                <ModalButton
                    isExpended={isExpendedAttendModal}
                    title={locale.willAttend}
                    selected={isRepliedCome}
                    disabled
                    onExpend={() => setExpendedAttendModal(true)}
                    onDismiss={onAttendModalDismiss}
                    {...BUTTON_PROPS}
                >
                    <div className={styles.attendButtonBody}>
                        {locale.totalAttending}
                        <div className={styles.section}>
                            <div className={styles.quantityCell}>
                                <QuantityPicker quantity={adultCount} onChangedQuantity={(quantity) => { setAdultCount(quantity) }} min={0} max={5} />
                                <div className={styles.quantityDesc}>{locale.adults}</div>
                            </div>
                            <div className={styles.quantityCell}>
                                <QuantityPicker quantity={childCount} onChangedQuantity={(quantity) => { setChildCount(quantity) }} min={0} max={5} />
                                <div className={styles.quantityDesc}>{locale.children}</div>
                            </div>
                        </div>
                        <div className={classnames([styles.section, styles.bottom])}>
                            {renderFormError(formError)}
                            <LightButton
                                width={150}
                                height={48}
                                fontSize={20}
                                onClick={() => onSubmitForm(true)}
                            >
                                {locale.confirm}
                            </LightButton>
                        </div>
                    </div>
                </ModalButton>
            </div>

            <div
                id={styles.notAttendButton}
                className={styles.attendenceButton}
            >
                <Button
                    selected={isRepliedNotCome}
                    onClick={() => onSubmitForm(false)}
                    disabled
                    {...BUTTON_PROPS}
                >
                    {locale.willNotAttend}
                </Button>
            </div>

            {
                isRepliedCome && (
                    <div id={styles.confirmedInfo}>
                        {locale.totalAttendCount(invitation.replyAdultCount ?? 0, invitation.replyChildCount ?? 0)}
                    </div>
                )
            }


            <div id={styles.msg} className={styles.info}>
                {locale.replyDeadlineMessage}
            </div>
            <div id={styles.date} className={classnames([styles.info, styles.meta])}>
                <div className={styles.title}>{locale.dateTimeTitle}</div>
                <div className={styles.content}>{locale.dateTimeLn1}<br />{locale.dateTimeLn2}<br />{locale.dateTimeLn3}</div>
            </div>
            <div id={styles.address} className={classnames([styles.info, styles.meta])}>
                <div className={styles.title}>{locale.addressTitle}</div>
                <div className={styles.content}>{locale.addressLn1}<br />{locale.addressLn2}<br />{locale.addressLn3}</div>
            </div>
            <CardFlipper onClick={flip} />
        </div>
    )
}

export default CardBack;
