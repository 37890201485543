import classnames from 'classnames';
import { FC, useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import useScale from '../../hooks/useScale';
import arrowImg from '../../img/top_right_arrow.png';
import i18n from '../../modules/i18n';
import CardBack from './CardBack';
import CardFront from './CardFront';
import styles from "./card.module.scss";

type Props = {
    invitation: Invitation,
    refreshInvite: () => Promise<boolean>,
    onReplied: (isCome: boolean) => void
};

const Card: FC<Props> = ({ invitation, refreshInvite, onReplied }) => {
    const [isFlipped, setFlip] = useState<boolean>(false);
    const [selectedLocale, setSelectedLocale] = useState<Locale>(invitation.preferredLocale);

    const scale = useScale();
    const locale = i18n[selectedLocale];

    const flip = () => {
        setFlip(!isFlipped);
    }

    const onSelectLocale = (locale: Locale) => {
        setSelectedLocale(locale);
    }

    return (
        <div className={styles.card} style={{ transform: `scale(${scale})` }}>
            <>
                <ReactCardFlip isFlipped={isFlipped} containerClassName={styles.cardFlipper}>
                    <CardFront flip={flip} invitation={invitation} refreshInvite={refreshInvite} selectedLocale={selectedLocale} onReplied={onReplied} />
                    <CardBack flip={flip} invitation={invitation} refreshInvite={refreshInvite} selectedLocale={selectedLocale} onReplied={onReplied} />
                </ReactCardFlip>
                <div className={styles.tools}>
                    <div className={styles.localeButtonContainer}>
                        <div
                            className={classnames(styles.localeButton, { [styles.active]: selectedLocale === "zh" })}
                            onClick={() => onSelectLocale("zh")}
                        >
                            中
                        </div>
                        <div
                            className={classnames(styles.localeButton, { [styles.active]: selectedLocale === "en" })}
                            onClick={() => onSelectLocale("en")}
                        >
                            EN
                        </div>
                    </div>

                    <div className={styles.flipTips}>
                        {locale.clickToFlip} <img src={arrowImg}/>
                    </div>
                </div>
            </>

        </div>
    )
}

export default Card;
