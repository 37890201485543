import { doc, getDoc, updateDoc, collection, onSnapshot, QuerySnapshot, DocumentData, FirestoreError, addDoc } from 'firebase/firestore';
import { db } from '../modules/firebase';

const COLN_INVITES = "invites";

export const getInviteInfo = (id: string) => {
    const infoRef = doc(db, COLN_INVITES, id);
    return getDoc(infoRef);
}

export const saveLastView = (id: string) => {
    const infoRef = doc(db, COLN_INVITES, id);
    return updateDoc(infoRef, {
        lastViewDatetime: new Date()
    });
}

export const replyInvite = async (id: string, isCome: boolean, adultCount: number | null, childCount: number | null) => {
    const infoRef = doc(db, COLN_INVITES, id);
    return updateDoc(infoRef, {
        replyDatetime: new Date(),
        replyIsCome: isCome,
        replyAdultCount: adultCount,
        replyChildCount: childCount
    });
}

export const streamInviteList = (snapshot: ((snapshot: QuerySnapshot<DocumentData>) => void), error: (error: FirestoreError) => void) => {
    const listRef = collection(db, COLN_INVITES);
    return onSnapshot(listRef, snapshot, error);
}

export const createNewInvite = async (data: CreateInviteData) => {
    return addDoc(collection(db, COLN_INVITES), {
        ...data,
        lastViewDatetime: null,
        replyIsCome: null,
        replyAdultCount: null,
        replyChildCount: null,
        replyDatetime: null
    } as Omit<Invitation, 'id'>);
}