import React, { FC, useEffect, useMemo, useState } from 'react';
import { createNewInvite, streamInviteList } from '../../services/firestoreService';
import { Table, Button, Card, Form } from "antd";
import type { ColumnsType } from 'antd/es/table';
import AppDomain from '../../modules/AppDomain';
import styles from "./index.module.scss";
import CreateInviteModal from '../../components/CreateInviteModal';

type Props = {};
type DataType = Invitation & {
    id: React.Key,
    displayReplyIsCome: string,
    displayReplyAdultCount: null | number,
    displayReplyChildCount: null | number
};

const columns: ColumnsType<DataType> = [
    {
        title: "Listed Name",
        dataIndex: "listName",
        filterMode: 'tree',
        filterSearch: true,
        onFilter: (value, record) => record.listName.includes(value + ""),
        fixed: 'left'
    },
    {
        title: "Side",
        dataIndex: "side",
        filters: [
            {
                text: 'F',
                value: 'F',
            },
            {
                text: 'M',
                value: 'M',
            }
        ],
        onFilter: (value, record) => record.side.indexOf(value + "") === 0,
        sorter: (a, b) => a.side.charCodeAt(0) - b.side.charCodeAt(0),
    },
    {
        title: "Relationship",
        dataIndex: "relationshipWithCouple"
    },
    {
        title: "Reply",
        dataIndex: "displayReplyIsCome",
        filters: [
            {
                text: 'Come',
                value: 'Come',
            },
            {
                text: 'Not Come',
                value: 'Not Come',
            },
            {
                text: '?',
                value: '?'
            }
        ],
        onFilter: (value, record) => record.displayReplyIsCome.indexOf(value + "") === 0,
        sorter: (a, b) => a.displayReplyIsCome.length - b.displayReplyIsCome.length,
    },
    {
        title: "Adult Count",
        dataIndex: "displayReplyAdultCount"
    },
    {
        title: "Child Count",
        dataIndex: "displayReplyChildCount"
    },
    {
        title: "Invite Link",
        dataIndex: "inviteLink",
        render: (_, record: { id: string }) => {
            return (
                <Button onClick={() => navigator.clipboard.writeText(`${AppDomain}/invite/${record.id}`)}>Copy Link</Button>
            )
        }
    }
]

const InviteListPage: FC<Props> = () => {
    const [inviteList, setInviteList] = useState<DataType[]>([]);
    const [isOpenCreateModal, setOpenCreateModal] = useState(false);
    const [isSubmittingCreateInvite, setSubmittingCreateInvite] = useState(false);
    const [form] = Form.useForm();

    const { maleFamilyCount, femaleFamilyCount } = useMemo(() => {
        const maleFamilyCount = {
            adult: 0,
            child: 0,
        };

        const femaleFamilyCount = {
            adult: 0,
            child: 0,
        };

        inviteList.forEach((item) => {
            if (item.side === "F") {
                femaleFamilyCount.adult += item.replyAdultCount ?? 0;
                femaleFamilyCount.child += item.replyChildCount ?? 0;
            } else {
                maleFamilyCount.adult += item.replyAdultCount ?? 0;
                maleFamilyCount.child += item.replyChildCount ?? 0;
            }
        });

        return { maleFamilyCount, femaleFamilyCount };
    }, [inviteList]);

    const onCreateNewInvite = (data: CreateInviteData) => {
        setSubmittingCreateInvite(true);
        createNewInvite(data).then(
            () => {
                setSubmittingCreateInvite(false);
                setOpenCreateModal(false);
                form.resetFields();
            },
            (e) => {
                alert("Create new invite failed, check console for details.");
                console.error("Create new invite failed", e);
                setSubmittingCreateInvite(false);
            }
        )
    }

    useEffect(() => {
        const unsubscribe = streamInviteList(
            (querySnapshot) => {
                const updatedItems = querySnapshot.docs.map(docSnapshot => {
                    const item = docSnapshot.data();
                    item.id = docSnapshot.id;
                    item.key = item.id;
                    item.displayReplyIsCome = (item.replyIsCome === null) ? "?" : (item.replyIsCome) ? "Come" : "Not Come";
                    item.displayReplyAdultCount = (item.replyIsCome === null) ? null : item.replyAdultCount;
                    item.displayReplyChildCount = (item.replyIsCome === null) ? null : item.replyChildCount;
                    return item;
                }) as DataType[];
                setInviteList(updatedItems);
                console.log(updatedItems);
            },
            (error) => console.error("List query error: ", error.code, error.name, error.message)
        );
        return unsubscribe;
    }, []);

    return (
        <div className={styles.inviteListPage} style={{paddingBottom: 100, paddingRight: 100}}>
                
                <div className={styles.title}>Wedding Invite List</div>
                <div className={styles.summaryContianer}>
                    <Card className={styles.metric}>
                        <div className={styles.header}>女家</div>
                        <div className={styles.content}>
                            <p>成人: {femaleFamilyCount.adult}</p>
                            <p>小童: {femaleFamilyCount.child}</p>
                        </div>
                    </Card>
                    <Card className={styles.metric}>
                        <div className={styles.header}>男家</div>
                        <div className={styles.content}>
                            <p>成人: {maleFamilyCount.adult}</p>
                            <p>小童: {maleFamilyCount.child}</p>
                        </div>
                    </Card>
                    <Card className={styles.metric}>
                        <div className={styles.header}>總計</div>
                        <div className={styles.content}>
                            <p>成人: {femaleFamilyCount.adult + maleFamilyCount.adult}</p>
                            <p>小童: {femaleFamilyCount.child + maleFamilyCount.child}</p>
                        </div>
                    </Card>
                </div>
                <Table bordered columns={columns} dataSource={inviteList} scroll={{ x: true }} />
                <div className={styles.createButton} onClick={() => setOpenCreateModal(true)}>＋</div>
                <CreateInviteModal
                    formInstance={form}
                    open={isOpenCreateModal}
                    confirmLoading={isSubmittingCreateInvite}
                    onSubmit={onCreateNewInvite}
                    onCancel={() => setOpenCreateModal(false)}
                />
            
        </div>
    );
}

export default InviteListPage;
