import classnames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';
import styles from "./index.module.scss";

type Props = PropsWithChildren & {
    width?: number;
    height?: number;
    fontSize?: number;
    selected?: boolean;
    onClick?: () => void;
};

const LightButton: FC<Props> = ({ children, width, height, fontSize, selected, onClick }) => {
    return (
        <div
            className={classnames([styles.button, { [styles.selected]: selected }])}
            style={{ width, height, fontSize }}
            onClick={onClick}
        >
            <span>{children}</span>
        </div>
    )
}

export default LightButton;
