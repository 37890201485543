import React, { FC } from "react";
import styles from './index.module.scss';

type Props = {
    quantity: number,
    min: number,
    max?: number,
    onChangedQuantity: (newQuantity: number) => void
};


const QuantityPicker: FC<Props> = ({ min, max, quantity, onChangedQuantity }) => {
    return (
        <div className={styles.quantityPicker}>
            <button
                className={styles.left}
                onClick={() => {
                    if (quantity > min) {
                        onChangedQuantity(quantity - 1);
                    }
                }}
            >－
            </button>
            <div className={styles.quantityContainer}>{quantity}</div>
            <button
                className={styles.right}
                onClick={() => {
                    if (max && quantity >= max) {
                        return;
                    }
                    onChangedQuantity(quantity + 1);
                }}
            >＋
            </button>
        </div>
    )
}

export default QuantityPicker;
