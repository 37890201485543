import React, { FC, PropsWithChildren } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import AppPath from '../modules/AppPath';
import AuthService from '../services/AuthService';

type Props = RouteProps;

const SecureRoute: FC<Props> = (props) => {
    const location = useLocation();

    if (!AuthService.isInitialized) {
        AuthService.init();
    }
    
    if (!AuthService.user) {
        return <Redirect to={`${AppPath.login}?from=${location.pathname}`}/>
    }

    return <Route {...props} />;
}

export default SecureRoute;
